import _ from "lodash";
import queryParams from "../helpers/queryParams";

export default [
  function onStateSettingsChanged(store) {
    store.watch(
      state => state.settings,
      _.debounce(function() {
        if (!store.state.isBrowserBack) {
          store.dispatch("updateURL");
        }
      }, 200),
      { deep: true }
    );
  },
  function onHistoryChanged(store) {
    window.onpopstate = () => {
      const params = queryParams.extractAllURLQueryParams();
      store.commit("SET_BROWSER_BACK", true);
      store.dispatch("updateSettings", params);
      setTimeout(() => {
        store.commit("SET_BROWSER_BACK", false);
      }, 250);
    };
  },
  function onInit(store) {

    const params = queryParams.extractAllURLQueryParams();

    params.code = params.code ? atob(decodeURIComponent(params.code)) : null;

    const settings = _.defaults(
      params,
      store.state.settings
    );

    store.dispatch("updateSettings", settings);

    if (store.state.settings.autoload) {
      store.dispatch("loadTag");
    }

  },
  function onWindowResize(store) {
    let bp = {};
    window.addEventListener("resize", _.debounce(() => onResize(bp), 250));

    setTimeout(() => {
      const style = getComputedStyle(document.body);
      bp = ["xs", "sm", "md", "lg", "xl"].reduce((all, bp) => {
        return (
          (all[bp] = parseInt(
            style.getPropertyValue("--breakpoint-" + bp).replace("px", ""),
            0
          )),
          all
        );
      }, {});

      onResize(bp);
    }, 500);

    function onResize({ xs, sm, md, lg, xl }) {
      const width = window.innerWidth;
      store.commit("SET_SCREEN", {
        xs: width >= xs && width < sm,
        sm: width >= sm && width < md,
        md: width >= md && width < lg,
        lg: width >= lg && width < xl,
        xl: width >= xl,
        gt: {
          xs: width >= xs,
          sm: width >= sm,
          md: width >= md,
          lg: width >= lg,
          xl: width >= xl
        },
        lt: {
          xs: width < sm,
          sm: width < md,
          md: width < lg,
          lg: width < xl,
          xl: width < 9999
        }
      });
    }
  }
];
