import { updateField } from "vuex-map-fields";

export default {
  updateField,
  SET_BROWSER_BACK: (state, isBrowserBack) =>
    (state.isBrowserBack = isBrowserBack),
  SET_URL: (state, { url }) => (state.url = url),
  SET_SETTINGS: (state, { settings }) =>
    (state.settings = { ...state.settings, ...settings }),
  SET_TAG_LOADED: (state, isTagLoaded) => (state.isTagLoaded = isTagLoaded),
  SET_LOADING: (state, loading) => (state.loading = loading),
  SET_SCREEN: (state, screen) => (state.screen = screen)
};
