<template>
  <div>
    <h5 class="mb-4 text-muted">
      Set the transaction data
      <span class="float-right h5">
        <b-icon-question-circle variant="primary" id="conversionHelp"></b-icon-question-circle>
      </span>
    </h5>
    <b-popover target="conversionHelp" triggers="hover" placement="leftbottom">
      <template v-slot:title>Help</template>
      <ul>
        <li v-for="(url, label) in helpLinks" :key="url">
          <a
            :href="url"
            target="_blank"
            @click="action('Help', 'LinkClick', 'TRANSACTION_TAG')"
          >{{label}}</a>
        </li>
      </ul>
    </b-popover>

    <b-form-group label-align="right" label-cols="3" label="tID">
      <b-form-input type="search" v-model="tID"></b-form-input>
      <b-form-text>Must be unique for each transaction.</b-form-text>
    </b-form-group>

    <b-form-group label-align="right" label-cols="3" label="cartAmount">
      <b-form-input v-model.number="cartAmount" type="number"></b-form-input>
      <b-form-text>Must be greater than 0 and demical separartor is a point.</b-form-text>
    </b-form-group>

    <p class="text-right">
      <b-button
        variant="link"
        @click="resetTransactionData(); action('Transaction', 'Clear')"
        :disabled="!isTransactionDataValid"
        >
        <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>&nbsp;Clear
      </b-button>&nbsp;
      <span id="record-transaction" class="d-inline-block">
        <b-button
          variant="outline-primary"
          @click="recordTransaction(); action('Transaction', 'Record', tID, cartAmount)"
          :disabled="!isTransactionDataValid || !isTagLoaded"
        >
          <b-icon-bag-fill></b-icon-bag-fill>&nbsp; Record transaction
        </b-button>
      </span>
      <b-tooltip target="record-transaction">To manually record a transaction, you must first load the iAdvize tag.</b-tooltip>
    </p>

    <SettingsSnippet
      :code="scriptTransaction"
      type="TRANSACTION_TAG"
    >The code must be inserted on your transaction confirmation page. It must be inserted before the main tag.</SettingsSnippet>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import SettingsSnippet from "./SettingsSnippet";

const helpLinks = {
  'Implement the conversion tag': 'https://help.iadvize.com/hc/en-gb/articles/360024974592',
  'Record transactions dynamically on SPA': 'https://help.iadvize.com/hc/en-gb/articles/206375538',
};

export default {
  components: {
    SettingsSnippet
  },
  data() {
    return {
      helpLinks
    };
  },
  computed: {
    ...mapFields(["settings.tID", "settings.cartAmount", "isTagLoaded"]),
    ...mapGetters(["isTransactionDataValid", "scriptTransaction"])
  },
  methods: {
    ...mapActions([
      'recordTransaction',
      'resetTransactionData',
    ]),
    action(eventCategory, eventAction, eventLabel, eventValue) {
      this.$gtag.event(eventCategory, eventAction, eventLabel, eventValue);
    }
  }
};
</script>

<style>
</style>
