<template>
  <div>
    <h5 class="mb-4 text-muted">
      Add custom data you need
      <span class="float-right h5">
        <b-icon-question-circle variant="primary" id="customDataHelp"></b-icon-question-circle>
      </span>
    </h5>
    <b-popover target="customDataHelp" triggers="hover" placement="leftbottom">
      <template v-slot:title>Help</template>
      <ul>
        <li v-for="(url, label) in helpLinks" :key="url">
          <a
            :href="url"
            target="_blank"
            @click="action('Help', 'LinkClick', 'CUSTOM_DATA_TAG')"
          >{{label}}</a>
        </li>
      </ul>
    </b-popover>

    <b-alert :show="!cd.length" variant="light">
      <b-icon-info-square></b-icon-info-square>&nbsp;Custom data list is empty, but you can
      <a
        href="#"
        @click.prevent="addCustomData(null)"
        class="alert-link"
      >add a custom data.</a>
    </b-alert>

    <b-form-group v-for="(customData, index) in cd" :key="index">
      <b-input-group>
        <b-form-input v-model="customData.key" placeholder="Name" :disabled="isTagLoaded"></b-form-input>
        <b-form-input v-model="customData.value" placeholder="Value" :disabled="isTagLoaded"></b-form-input>
        <b-input-group-append>
          <b-button
            variant="outline-danger"
            @click="removeCustomData(index); action('CustomData', 'Remove')"
            :disabled="isTagLoaded"
          >
            <b-icon-trash></b-icon-trash>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <p class="text-right">
      <b-button variant="link" @click="resetCustomData(); action('CustomData', 'Clear')" v-show="cd.length" :disabled="isTagLoaded">
        <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>&nbsp;Clear
      </b-button>&nbsp;
      <b-dropdown
        split
        right
        dropup
        variant="outline-primary"
        @click="addCustomData(null); action('CustomData', 'Add', standardCustomData)"
        :disabled="isTagLoaded"
      >
        <template v-slot:button-content>
          <b-icon-plus></b-icon-plus>&nbsp;Add
        </template>
        <b-dropdown-header>Choose a standard custom data...</b-dropdown-header>
        <b-dropdown-item
          @click="addCustomData(standardCustomData); action('CustomData', 'Add', standardCustomData)"
          v-for="(standardCustomData, index) in standardsCustomData"
          :key="index"
        >{{standardCustomData}}</b-dropdown-item>
      </b-dropdown>
    </p>

    <SettingsSnippet
      :code="scriptCustomData"
      type="CUSTOM_DATA_TAG"
    >This script must be added to all pages on the site. It must be inserted before the main tag.</SettingsSnippet>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import SettingsSnippet from "./SettingsSnippet";

const helpLinks = {
  'Create and use custom data': 'https://help.iadvize.com/hc/en-gb/articles/203401593',
  'Map customer users to visitors using extID': 'https://help.iadvize.com/hc/en-gb/articles/204497293',
  'Visitor informations are filled from standard custom data': 'https://help.iadvize.com/hc/en-gb/articles/206855657',
};

export default {
  components: {
    SettingsSnippet
  },
  data() {
    return {
      helpLinks
    };
  },
  computed: {
    ...mapMultiRowFields(["settings.cd"]),
    ...mapState(["standardsCustomData", "isTagLoaded"]),
    ...mapGetters(["scriptCustomData"])
  },
  methods: {
    ...mapActions([
      'removeCustomData',
      'addCustomData',
      'resetCustomData',
    ]),
    action(eventCategory, eventAction, eventLabel, eventValue) {
      this.$gtag.event(eventCategory, eventAction, eventLabel, eventValue);
    }
  }
};
</script>

<style>
</style>
