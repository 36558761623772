export default class Log {
  constructor({ content, topic, level, context = null }) {
    this.content = content;
    this.topic = topic;
    this.level = level;
    this.context = context;
    this.createdAt = new Date();
    this.humanReadableTopic = topicMapping[topic];
    this.variant = variantMapping[level];
    this.consoleLogFunction = consoleLogMapping[level];
  }
  getToastOptions() {
    return {
      title: this.humanReadableTopic,
      variant: this.variant,
      solid: true,
    };
  }
}

Log.level = {
  INFO: 0,
  WARNING: 1,
  ERROR: 2
};

Log.topic = {
  CALLBACK: 0,
  TAG: 1,
  TRANSACTION: 2,
  CUSTOM_DATA: 3,
  NAVIGATE: 4,
  VISITOR: 5
};

Log.getTopicMapping = () => topicMapping;

Log.info = (topic, content, context) =>
  new Log({ content, topic, level: Log.level.INFO, context });
Log.warn = (topic, content, context) =>
  new Log({ content, topic, level: Log.level.WARNING, context });
Log.error = (topic, content, context) =>
  new Log({ content, topic, level: Log.level.ERROR, context });

const consoleLogMapping = {
  [Log.level.INFO]: "info",
  [Log.level.WARNING]: "warn",
  [Log.level.ERROR]: "error"
};

const variantMapping = {
  [Log.level.INFO]: "primary",
  [Log.level.WARNING]: "warning",
  [Log.level.ERROR]: "danger"
};

const topicMapping = {
  [Log.topic.CALLBACK]: "Callback",
  [Log.topic.TAG]: "Tag",
  [Log.topic.TRANSACTION]: "Transaction",
  [Log.topic.CUSTOM_DATA]: "Custom data",
  [Log.topic.NAVIGATE]: "Navigate",
  [Log.topic.VISITOR]: "Visitor"
};
