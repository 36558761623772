<template>
  <div>
    <b-jumbotron class="intro rounded-0">
      <b-container fluid="lg">
        <span><h1>iAdvize Playground</h1></span>
        <img
          src="https://developers.iadvize.com/bundles/devplatformapp/images/logos/iadvizeColored.svg"
        >
      </b-container>
    </b-jumbotron>
    <b-container fluid="lg">
      <TheTestBench/>
    </b-container>
  </div>
</template>

<script>
import TheTestBench from "./components/TheTestBench";

export default {
  name: "App",
  data() {
    return {
      modalId: "iadvize-test-bench-modal"
    };
  },
  components: {
    TheTestBench
  },
  created() {
    this.$store.watch(
      (state, getters) => getters["lastLog"],
      log => {
        this.$bvToast.toast(log.content, {
          ...log.getToastOptions(),
          toaster: 'b-toaster-top-left',
          appendToast: true,
        });
        console.groupCollapsed(
          `%c[iAdvize Test bench] ${log.humanReadableTopic}`,
          "padding: 5px; background: #f8f9fb; color: #1bc74e"
        );
        console[log.consoleLogFunction](
          log.content,
          log.context || "(No context)"
        );
        console.groupEnd();
      }
    );
  }
};
</script>

<style>
.jumbotron.intro {
  padding: 2rem 2rem;
}
</style>
