<template>
  <div>
    <h5 class="mb-4 text-muted">
      Configure the main iAdvize Tag
      <span class="float-right h5">
        <b-icon-question-circle variant="primary" id="tagHelp"></b-icon-question-circle>
      </span>
    </h5>
    <b-popover target="tagHelp" triggers="hover" placement="leftbottom">
      <template v-slot:title>Help</template>
      <ul>
        <li v-for="(url, label) in helpLinks" :key="url">
          <a
            :href="url"
            target="_blank"
            @click="action('Help', 'LinkClick', 'MAIN_TAG')"
          >{{label}}</a>
        </li>
      </ul>
    </b-popover>

    <b-form-group label-align="right">
      <b-input-group >
        <b-input-group-prepend>
          <b-dropdown :text="env.toUpperCase()" variant="outline-primary" :disabled="isTagLoaded">
            <b-dropdown-item
              v-for="(envItem, index) in envAliases"
              :key="index"
              @click="env = envItem"
            >{{envItem.toUpperCase()}}</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="selectCustomEnvironment">Custom...</b-dropdown-item>
          </b-dropdown>
        </b-input-group-prepend>
        <b-form-input
          v-model.number="sid"
          type="number"
          placeholder="SID"
          :disabled="isTagLoaded"
          class="border-primary"
          autofocus
        ></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-card class="text-muted" sub-title="Settings" bg-variant="light">

      <b-form-group label-align="right" label-cols="3" label="Project URL" class="mb-0">
        <b-form-input
          type="search"
          class="text-muted"
          size="sm"
          v-model="domain"
          placeholder="Enter the URL set in your iAdvize Project"
          :disabled="isTagLoaded"
        ></b-form-input>
      </b-form-group>

      <b-form-group label-align="right" label-cols="3" label="Language (optional)" class="mb-0">
        <b-form-input
          type="search"
          class="text-muted"
          size="sm"
          v-model="lang"
          placeholder="You can force the language: fr, en, de, ..."
          :disabled="isTagLoaded"
        ></b-form-input>
      </b-form-group>

      <b-form-group label-align="right" label-cols="3" label="Template (optional)" class="mb-0">
        <b-form-input
          type="search"
          class="text-muted"
          size="sm"
          v-model="tpl"
          placeholder="For old iAdvize configuration"
          :disabled="isTagLoaded"
        ></b-form-input>
      </b-form-group>
    </b-card>

    <SettingsSnippet
      :code="scriptTag"
      type="MAIN_TAG"
    >Include this script on all pages of your website just before the closing tags &lt;/body&gt;</SettingsSnippet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import SettingsSnippet from "./SettingsSnippet";

const helpLinks = {
  'Implement the iAdvize tag': 'https://help.iadvize.com/hc/en-gb/articles/202575988',
};

export default {
  components: {
    SettingsSnippet
  },
  data() {
    return {
      helpLinks
    };
  },
  methods: {
    selectCustomEnvironment() {
      const customEnvironment = prompt(
        "Enter host name of livechat for the environment you want load (ex: [YOUR_ENVIRONMENT_SUB_DOMAIN].iadvize.com). For dev tracks, use livechat-[TRACK].iadvize.com",
        "livechat-[TRACK].iadvize.com"
      );
      if (customEnvironment) {
        this.envAliases.push(customEnvironment);
        this.env = customEnvironment;
      }
    },
    action(eventCategory, eventAction, eventLabel, eventValue) {
      this.$gtag.event(eventCategory, eventAction, eventLabel, eventValue);
    }
  },
  computed: {
    ...mapFields([
      "isTagLoaded",
      "settings.env",
      "settings.sid",
      "settings.lang",
      "settings.tpl",
      "settings.domain"
    ]),
    ...mapGetters(["envAliases", "scriptTag"])
  }
};
</script>

<style>
</style>
