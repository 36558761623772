<template>
  <div>
    <b-alert :show="!allLogsReversed.length" variant="light">
      <b-icon-info-square></b-icon-info-square>&nbsp;Empty trace log
    </b-alert>
    <ul class="timeline pt-1 pb-1">
      <li v-for="log in allLogsReversed"
        :key="log.createdAt.toISOString()"
        class="mb-4 mt-4"
        >
        <b-iconstack class="timeline-icon">
          <b-icon stacked icon="circle-fill" :variant="log.variant"></b-icon>
          <b-icon stacked :icon="getLogLevelIcon(log.level)" scale="0.75" variant="white"></b-icon>
        </b-iconstack>
        <b-card
          header-tag="header"
          footer-tag="footer"
          header-text-variant="muted"
          no-body
        >
          <template v-slot:header>
            <h6 class="mb-0">{{log.createdAt | formatDate}} - {{log.humanReadableTopic}}</h6>
          </template>
          <b-card-text class="m-0">
            <h6 class="m-3">{{log.content}}</h6>
            <AppCode
              v-if="log.context"
              class="m-0"
              :code="JSON.stringify(log.context, null, '\t')"
              mode="application/json"
              readonly
            />
          </b-card-text>
        </b-card>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppCode from "./AppCode";
import Log from "../models/Log";

const logsLevelIcon = {
  [Log.level.INFO]: 'info',
  [Log.level.WARNING]: 'exclamation',
  [Log.level.ERROR]: 'x',
};

export default {
  components: {
    AppCode
  },
  computed: {
    ...mapGetters(["allLogsReversed"])
  },
  methods: {
    getLogLevelIcon(logLevel) {
      return logsLevelIcon[logLevel];
    }
  },
  filters: {
    formatDate: function (date) {
      if (!date) return '';
      const // year = date.getFullYear(),
            // month = date.getMonth() + 1, // months are zero indexed
            // day = date.getDate(),
            hour = date.getHours(),
            minute = date.getMinutes(),
            second = date.getSeconds(),
            hourFormatted = hour < 10 ? '0'+hour : hour, // hour returned in 24 hour format
            minuteFormatted = minute < 10 ? '0'+minute : minute;

      return hourFormatted+':'+minuteFormatted+':'+second;
    }
  }
};
</script>

<style>
ul.timeline {
  list-style-type: none;
  position: relative;
  margin: 0;
  padding: 0;
  padding-left: 30px;
}

ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 0px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline > li {
  position: relative;
}

ul.timeline > li > .timeline-icon {
  position: absolute;
  left: -44px;
  top: 6px;
  height: 30px;
  width: 30px;
  z-index: 500;
}

ul.timeline > li:before {
  position: absolute;
  top: 6px;
  right: -15px;
  display: inline-block;
  border-top: 15px solid transparent;
  border-left: 0;
  border-right: 15px solid #ccc;
  border-bottom: 15px solid transparent;
  content: " ";
  left: -14px;
  right: auto;
}

ul.timeline > li:after {
  position: absolute;
  top: 7px;
  right: -14px;
  display: inline-block;
  border-top: 14px solid transparent;
  border-left: 0;
  border-right: 14px solid #F7F7F7;
  border-bottom: 14px solid transparent;
  content: " ";
  left: -13px;
  right: auto;
}
</style>
