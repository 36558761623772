import Vue from "vue";
import VueGtag from "vue-gtag";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import App from "./App.vue";
import store from "./store";

Vue.use(VueGtag, {
  config: {
    id: 'UA-129125465-2'
  }
});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  store
}).$mount('#app');