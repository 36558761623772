<template>
  <div>
    <h5 class="mb-4 text-muted">
      Configure your iAdvize fixed buttons
      <span class="float-right h5">
        <b-icon-question-circle variant="primary" id="fixedButtonHelp"></b-icon-question-circle>
      </span>
    </h5>
    <b-popover target="fixedButtonHelp" triggers="hover" placement="leftbottom">
      <template v-slot:title>Help</template>
      <ul>
        <li v-for="(url, label) in helpLinks" :key="url">
          <a
            :href="url"
            target="_blank"
            @click="action('Help', 'LinkClick', 'FIXED_BUTTON')"
          >{{label}}</a>
        </li>
      </ul>
    </b-popover>
    <b-card class="m-3">

      <b-input-group prepend="Available selector">
        <b-form-input
          placeholder="#my-id-selector or .my-class-selector"
          v-model="fba"
          :disabled="isTagLoaded"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            variant="success"
            :class="getFixedButtonSelector(fba, 'CLASS')"
            :id="getFixedButtonSelector(fba, 'ID')"
            style="display: none"
            >Available Button</b-button>
        </b-input-group-append>
      </b-input-group>

      <b-input-group prepend="Busy selector" class="mt-3">
        <b-form-input
          placeholder="#my-id-selector or .my-class-selector"
          v-model="fbb"
          :disabled="isTagLoaded"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            variant="warning"
            :class="getFixedButtonSelector(fbb, 'CLASS')"
            :id="getFixedButtonSelector(fbb, 'ID')"
            style="display: none"
            >Busy Button</b-button>
        </b-input-group-append>
      </b-input-group>

      <b-input-group prepend="Unavailable selector" class="mt-3">
        <b-form-input
          placeholder="#my-id-selector or .my-class-selector"
          v-model="fbu"
          :disabled="isTagLoaded"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            variant="danger"
            :class="getFixedButtonSelector(fbu, 'CLASS')"
            :id="getFixedButtonSelector(fbu, 'ID')"
            style="display: none"
            >Unavailable Button</b-button>
        </b-input-group-append>
      </b-input-group>

    </b-card>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

const helpLinks = {
  'Implement a fixed button': 'https://help.iadvize.com/hc/en-gb/articles/205165908',
};

export default {
  data() {
    return {
      helpLinks
    };
  },
  computed: {
    ...mapFields([
      'isTagLoaded',
    ]),
    fba: {
      get() {
        const fb = this.$store.state.settings.fba;
        return fb ? decodeURIComponent(fb) : '';
      },
      set(value) {
        this.$store.dispatch('updateSettings', {fba: encodeURIComponent(value)});
      }
    },
    fbb: {
      get() {
        const fb = this.$store.state.settings.fbb;
        return fb ? decodeURIComponent(fb) : '';
      },
      set(value) {
        this.$store.dispatch('updateSettings', {fbb: encodeURIComponent(value)});
      }
    },
    fbu: {
      get() {
        const fb = this.$store.state.settings.fbu;
        return fb ? decodeURIComponent(fb) : '';
      },
      set(value) {
        this.$store.dispatch('updateSettings', {fbu: encodeURIComponent(value)});
      }
    }
  },
  methods: {
    getFixedButtonSelector(selectorDefinition, type) {

      const selectorType = selectorDefinition.charAt(0) === '#' ? 'ID' : 'CLASS';

      return type.toUpperCase() === selectorType.toUpperCase()
        ? selectorDefinition.substring(1)
        : null;

    },
    action(eventCategory, eventAction, eventLabel, eventValue) {
      this.$gtag.event(eventCategory, eventAction, eventLabel, eventValue);
    }
  }
}
</script>

<style>

</style>