<template>
  <div>
    <div no-body>
      <b-tabs card :vertical="verticalTabs" :pills="verticalTabs" :fill="!verticalTabs">
        <b-tab @click="onTabSelected('TAG_PARAMS')">
          <template v-slot:title>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                <b-icon-chat></b-icon-chat>&nbsp;Main Tag&nbsp;
              </span>
              <b-badge
                v-if="isTagParamsValid"
                :variant="selectedTab === 'TAG_PARAMS' ? 'primary' : 'light'"
              >
                <b-icon-check :variant="selectedTab === 'TAG_PARAMS' ? 'light' : 'primary'"></b-icon-check>
              </b-badge>
            </div>
          </template>
          <SettingsTagParams/>
        </b-tab>
        <b-tab @click="onTabSelected('TRANSACTION')">
          <template v-slot:title>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                <b-icon-bag></b-icon-bag>&nbsp;Transaction&nbsp;
              </span>
              <b-badge
                v-if="isTransactionDataValid"
                :variant="selectedTab === 'TRANSACTION' ? 'primary' : 'light'"
              >
                <b-icon-check :variant="selectedTab === 'TRANSACTION' ? 'light' : 'primary'"></b-icon-check>
              </b-badge>
            </div>
          </template>
          <SettingsConversion/>
        </b-tab>
        <b-tab @click="onTabSelected('CUSTOM_DATA')">
          <template v-slot:title>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                <b-icon-collection></b-icon-collection>&nbsp;Custom Data&nbsp;
              </span>
              <b-badge
                :variant="selectedTab === 'CUSTOM_DATA' ? 'light' : 'primary'"
              >{{customDataCount}}</b-badge>
            </div>
          </template>
          <SettingsCustomData/>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import SettingsTagParams from "./SettingsTagParams";
import SettingsCustomData from "./SettingsCustomData";
import SettingsConversion from "./SettingsConversion";

const gaTabsPageMapping = {
  'TAG_PARAMS': '/tags/main',
  'TRANSACTION': '/tags/transaction',
  'CUSTOM_DATA': '/tags/custom-data',
};

export default {
  components: {
    SettingsTagParams,
    SettingsCustomData,
    SettingsConversion
  },
  data() {
    return {
      selectedTab: null,
    };
  },
  methods: {
    onTabSelected(tab) {
      this.selectedTab = tab;
      this.$gtag.pageview(gaTabsPageMapping[tab]);
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings,
      screen: state => state.screen
    }),
    ...mapGetters([
      "validCustomData",
      "isTransactionDataValid",
      "isTagParamsValid"
    ]),
    customDataCount() {
      return this.validCustomData.length;
    },
    verticalTabs() {
      return this.screen.gt.md;
    }
  },
  mounted() {
    this.onTabSelected('TAG_PARAMS');
  }
};
</script>

<style>
.nav.flex-column {
  width: 250px;
}

.tab-content {
  min-width: 0;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
