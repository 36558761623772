<template>
  <div>
    <b-overlay
      :show="loading"
      z-index="9999999"
      spinner-variant="success"
      spinner-type="grow"
      no-wrap
    ></b-overlay>
    <b-card title="Card Title" no-body>
      <b-tabs card>
        <b-tab no-body @click="onTabSelected('TAGS')">
          <template v-slot:title>
            <b-icon-code-slash></b-icon-code-slash>&nbsp;Tags
          </template>
          <b-card-text>
            <Settings/>
          </b-card-text>
        </b-tab>
        <b-tab @click="onTabSelected('JAVASCRIPT_CALLBACKS')" no-body>
          <template v-slot:title>
            <b-icon-braces></b-icon-braces>&nbsp;JS Callbacks
          </template>
          <b-card-text>
            <Playground/>
          </b-card-text>
        </b-tab>
        <b-tab @click="onTabSelected('FIXED_BUTTONS')">
          <template v-slot:title>
            <b-icon-view-stacked></b-icon-view-stacked>&nbsp;Fixed Buttons
          </template>
          <b-card-text>
            <FixedButtons/>
          </b-card-text>
        </b-tab>
        <b-tab @click="onTabSelected('LOGS')">
          <template v-slot:title>
            <b-icon-newspaper></b-icon-newspaper>&nbsp;Logs
          </template>
          <b-card-text>
            <Logs/>
          </b-card-text>
        </b-tab>
      </b-tabs>
      <template v-slot:footer>
        <TheActionBar/>
      </template>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TheActionBar from './TheActionBar';
import Playground from './Playground';
import Settings from './Settings';
import Logs from './Logs';
import FixedButtons from './FixedButtons';

const gaTabsPageMapping = {
  'TAGS': '/tags/main',
  'JAVASCRIPT_CALLBACKS': '/javascript-callbacks',
  'LOGS': '/logs',
};

export default {
  components: {
    TheActionBar,
    Playground,
    Settings,
    Logs,
    FixedButtons,
  },
  data() {
    return {
      selectedTab: null,
    };
  },
  computed: {
    ...mapState([
      'settings',
      'loading',
    ])
  },
  methods: {
    onTabSelected(tab) {
      this.selectedTab = tab;
      this.$gtag.pageview(gaTabsPageMapping[tab]);
    }
  },
  mounted() {
    this.onTabSelected('TAGS');
  }
};
</script>

<style scoped>
* >>> [disabled] {
  cursor: not-allowed;
}

* >>> .bi-question-circle {
  cursor: pointer;
}
</style>
