import { getField } from "vuex-map-fields";

export default {
  getField,
  customData: state => state.settings.cd,
  validCustomData: (state, getters) => getters.customData.filter(cd => cd.key),
  validCustomDataKeyValue: (state, getters) =>
    getters.validCustomData.reduce(
      (res, cd) => ((res[cd.key] = cd.value), res),
      {}
    ),
  validTransactionDataKeyValue: ({ settings }) =>
    settings.tID && settings.cartAmount
      ? { tID: settings.tID, cartAmount: settings.cartAmount }
      : null,
  isTransactionDataValid: (state, getters) =>
    !!getters.validTransactionDataKeyValue,
  isTagParamsValid: ({ settings }) => settings.env && settings.sid,
  settingsForQueryParams: (state, getters) => ({
    ...state.settings,
    cd: getters.validCustomData,
    code: getters.b64Code
  }),
  b64Code: state => state.settings.code ? encodeURIComponent(btoa(state.settings.code)) : null,
  envAliases: state => Object.keys(state.envDomainMappings),
  envDomains: state => Object.values(state.envDomainMappings),
  tagHostname: state => {
    const env = state.envDomainMappings[state.settings.env || "ha"];
    return env ? `${env}.iadvize.com` : state.settings.env;
  },
  idzTagOptions: (state, getters) => {
    const options = {
      sid: state.settings.sid,
      hostname: getters.tagHostname,
      lang: state.settings.lang ? "&lang=" + state.settings.lang : "",
      tpl: state.settings.tpl ? "&tpl=" + state.settings.tpl : ""
    };
    return options;
  },
  scriptTag(state, { idzTagOptions }) {
    return `<!-- START IADVIZE LIVECHAT -->
<script type="text/javascript">
(function() {
var idz = document.createElement("script");
idz.type = "text/javascript";
idz.async = true;
idz.src = document.location.protocol + "//${
      idzTagOptions.hostname
    }/iadvize.js?sid=${idzTagOptions.sid}${idzTagOptions.lang}${
      idzTagOptions.tpl
    }";
var s = document.getElementsByTagName("script")[0];
s.parentNode.insertBefore(idz, s);
})();
</script>
<!-- END IADVIZE LIVECHAT -->`;
  },
  scriptTransaction(state) {
    if (!state.settings.tID && (state.settings.cartAmount === null || state.settings.cartAmount === '')) {
      return '';
    }
    return `<!-- START IADVIZE CONVERSION TRACKING CODE -->
<script type="text/javascript">
window.idzTrans = {
  tID: ${state.settings.tID ? `"${state.settings.tID}"` : null},
  cartAmount: ${state.settings.cartAmount === null || state.settings.cartAmount === '' ? null : state.settings.cartAmount}
};
</script>
<!-- END IADVIZE CONVERSION TRACKING CODE -->`;
  },
  scriptCustomData(state, getters) {
    return `<script type="text/javascript">
window.idzCustomData = {${getters.validCustomData.reduce(
      (all, cd) => (
        (all += `\n  "${cd.key}": ${cd.value ? `"${cd.value}"` : null},`), all
      ),
      ""
    )}\n};
</script>`;
  }
};
