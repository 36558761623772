<template>
  <div>
    <b-modal
      id="modal-share-url"
      title="Share configuration"
      centered
      hide-footer
      header-bg-variant="light"
      footer-bg-variant="light"
    >
      <b-form-group>
        <b-input-group size="sm">
          <b-form-input @click="selectURL" ref="url" :value="url" readonly></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary" v-b-tooltip.hover title="Click to copy the URL in your clipboard" @click="copySharableURLToClipboard"><b-icon-clipboard-data></b-icon-clipboard-data> Copy</b-button>
            <b-button
              id="qrcode-button"
              variant="outline-primary"
              @click="copySharableURLToClipboard"
            ><b-icon-grid3x3-gap-fill></b-icon-grid3x3-gap-fill> QRCode</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-popover target="qrcode-button" triggers="hover" placement="bottomleft">
        <canvas id="qrcodeCanvas"></canvas>
      </b-popover>
      <b-card bg-variant="light" border-variant="primary">

        <b-form-group>
          <b-input-group>
            <template v-slot:prepend>
              <b-input-group-text class="bg-light">Default path :</b-input-group-text>
            </template>
            <b-form-input v-model="sharablePath" type="search"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-card-text>
          <b-form-group>
            <b-form-checkbox
              v-model="autoload"
              :value="true"
              :unchecked-value="false"
              switch
            >Automatically load the iAdvize tag</b-form-checkbox>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox
              v-model="isCodeSharable"
              :value="true"
              :unchecked-value="false"
              switch
            >Share JavaScript Callbacks</b-form-checkbox>
          </b-form-group>
          
        </b-card-text>
      </b-card>
    </b-modal>
    <b-button-group id="load-tag-wrapper">
      <b-button
        variant="primary"
        @click="loadTag();action('LoadTag')"
        :disabled="isTagLoaded || !isTagParamsValid"
        >
        <b-icon-code-slash></b-icon-code-slash>&nbsp;Load Tag
      </b-button>

      <b-dropdown :disabled="!isTagLoaded" right dropup variant="outline-primary">
        <template v-slot:button-content>
          <b-icon-cursor-fill></b-icon-cursor-fill>&nbsp;Navigate to...
        </template>
        <b-dropdown-header>Choose a default path...</b-dropdown-header>
        <b-dropdown-item
          @click="navigateTo(path);action('NavigateTo')"
          v-for="(path, index) in paths"
          :key="index"
        >{{path}}</b-dropdown-item>
        <b-dropdown-header>Or...</b-dropdown-header>
        <b-dropdown-item @click="navigateTo();action('NavigateTo')">Navigate to a custom path...</b-dropdown-item>
      </b-dropdown>

      <b-button :disabled="!isTagLoaded" variant="outline-warning" @click="resetVisitor();action('ResetVisitor')">
        <b-icon-bootstrap-reboot></b-icon-bootstrap-reboot>&nbsp;Reset visitor
      </b-button>

    </b-button-group>&nbsp;
    <b-tooltip target="load-tag-wrapper" triggers="hover">
      <span v-if="!isTagLoaded && !isTagParamsValid">Select an environment and set your project ID</span>
      <span v-if="!isTagLoaded && isTagParamsValid">Don't forget to load the tag</span>
    </b-tooltip>
    <b-button variant="link" v-b-modal.modal-share-url @click="action('Share')">
      <b-icon-reply></b-icon-reply>&nbsp;Share
    </b-button>
  </div>
</template>

<script>
import QrCreator from 'qr-creator';
import { mapGetters, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import queryParams from "../helpers/queryParams";

export default {
  data() {
    return {
      isCodeSharable: true,
      autoload: true,
      sharablePath: null,
    };
  },
  methods: {
    ...mapActions([
      "loadTag",
      "navigateTo",
      "resetVisitor",
    ]),
    copySharableURLToClipboard() {
      this.selectURL();
      document.execCommand('copy');
    },
    selectURL() {
      var domElement = this.$refs.url;
      domElement.focus();
      domElement.select();
    },
    action(eventAction) {
      this.$gtag.event('Action', eventAction);
    }
  },
  computed: {
    ...mapFields([
      "paths",
      "isTagLoaded",
      "settings.path",
    ]),
    ...mapGetters([
      "isTagParamsValid",
      "settingsForQueryParams"
    ]),
    url() {
      const params = {
        ...this.settingsForQueryParams,
        code: this.isCodeSharable ? this.settingsForQueryParams.code : null,
        autoload: this.autoload,
        path: this.sharablePath,
      };
      return queryParams.buildFullURLQueryParamsFromSettings(params);
    }
  },
  mounted() {
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId === "modal-share-url") {
        this.sharablePath = this.path;
      }
    });
    this.$root.$on('bv::popover::shown', () => {
      const canvas = document.getElementById('qrcodeCanvas');
      canvas.style.width = '100%';
      QrCreator.render({
        text: this.url,
        radius: 0.3, // 0.0 to 0.5
        ecLevel: 'M', // L, M, Q, H
        fill: 'rgb(27, 199, 78)', // foreground color
        background: null, // color or null for transparent
        size: 512 // in pixels
      }, canvas);
    });
  }
};
</script>

<style>
label {
  cursor: pointer;
}
</style>
