<template>
  <div>
    <b-badge @click="action('ToggleVisibility', type)" href="#" class="mt-3" v-b-toggle="codemirrorCollapseID" variant="primary">
      <span class="when-opened">
        <b-icon-eye-slash-fill></b-icon-eye-slash-fill>&nbsp;Hide
      </span>
      <span class="when-closed">
        <b-icon-eye-fill></b-icon-eye-fill>&nbsp;Show
      </span> code
    </b-badge>
    <b-collapse :id="codemirrorCollapseID" class="mt-3">
      <b-card bg-variant="light">
        <b-alert show variant="light">
          <b-icon-info-square></b-icon-info-square>&nbsp;
          <slot></slot>
        </b-alert>
        <AppCode
          class="settings-snippet"
          mode="text/html"
          :code="code"
          :read-only="true"
        />
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import AppCode from "./AppCode";

export default {
  components: {
    AppCode
  },
  props: {
    code: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      codemirrorCollapseID: this.getRandomCodemirrorCollapseID()
    };
  },
  methods: {
    getRandomCodemirrorCollapseID() {
      return Math.random()
        .toString(36)
        .substring(2, 15);
    },
    action(eventAction, eventLabel) {
      this.$gtag.event('Snippet', eventAction, eventLabel);
    }
  }
};
</script>

<style>
.settings-snippet .CodeMirror {
  border-radius: 5px !important;
  overflow: hidden;
}
</style>
