<template>
  <codemirror
    :ref="ref"
    :value="sourceCode"
    :options="cmOptions"
    @ready="onCmReady"
    @focus="onCmFocus"
    @input="onCmCodeChange"
  />
</template>

<script>
import { CodeMirror, codemirror } from "vue-codemirror";
import addAutoRefreshAddon from "../helpers/cmAutoRefreshAddon.js";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/htmlmixed/htmlmixed.js";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/dracula.css";

addAutoRefreshAddon(CodeMirror);

export default {
  components: {
    codemirror
  },
  props: {
    code: String,
    mode: String,
    readOnly: {
      type: Boolean,
      default: false
    },
    lineNumbers: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      sourceCode: this.code,
      ref: this.getRandomRef(),
      cmOptions: {
        tabSize: 4,
        mode: this.mode || "text/javascript",
        theme: "dracula",
        lineNumbers: this.lineNumbers,
        line: true,
        autoRefresh: true,
        readOnly: this.readOnly
      }
    };
  },
  watch: {
    code(code) {
      this.sourceCode = code || '';
    },
    readOnly(newValue) {
      this.cm.setOption('readOnly', newValue);
    }
  },
  methods: {
    onCmReady() {},
    onCmFocus() {},
    onCmCodeChange(code) {
      this.sourceCode = code;
      this.$emit("onCodeChange", code);
    },
    getRandomRef() {
      return Math.random()
        .toString(36)
        .substring(2, 15);
    }
  },
  computed: {
    cm() {
      return this.$refs[this.ref].codemirror;
    }
  }
};
</script>

<style>
.CodeMirror {
  height: auto;
}
</style>
