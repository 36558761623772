<template>
  <div>
    <b-card no-body class="border-0">
      <template v-slot:header bg-variant="white">
        <b-dropdown variant="outline-primary" size="sm">
          <template v-slot:button-content>
            <b-icon-file-earmark-code></b-icon-file-earmark-code>&nbsp;Load snippet...
          </template>
          <b-dropdown-item
            v-for="(snippet, code) in snippets"
            @click="loadSnippet(code)"
            :key="code"
          >{{snippet.label}}</b-dropdown-item>
        </b-dropdown>
        <span class="float-right h4 m-0">
          <b-icon-question-circle variant="primary" id="playgroundHelp"></b-icon-question-circle>
        </span>
      </template>
      <b-popover target="playgroundHelp" triggers="hover" placement="leftbottom">
        <template v-slot:title>Help</template>
        <p>You can play with our JavaScript callbacks. Changes are automatically evaluated by the browser.</p>
        <ul>
          <li v-for="(url, label) in helpLinks" :key="url">
            <a
              :href="url"
              target="_blank"
              @click="action('Help', 'LinkClick', 'JAVASCRIPT_CALLBACKS')"
            >{{label}}</a>
          </li>
        </ul>
      </b-popover>
      <AppCode class="playground-code" :code="code" 
          :read-only="isTagLoaded" @onCodeChange="onCodeChange"/>
      <b-list-group class="list-group-errors rounded-0" flush>
        <b-list-group-item class="d-flex bg-dark" v-for="(error, index) in errorsList" :key="index">
          <b-badge variant="danger" class="mr-2">Error</b-badge>
          <span class="d-block text-danger">{{error}}</span>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import Log from "../models/Log";
import AppCode from "./AppCode";
import { mapFields } from "vuex-map-fields";

const callbacks = [
  "onChatDisplayed",
  "onChatButtonDisplayed",
  "onChatStarted",
  "onChatEnded",
  "onCallButtonDisplayed",
  "onMessageReceived",
  "onMessageSent",
  "onSatisfactionDisplayed",
  "onSatisfactionAnswered",
  "onCallStarted",
  "onCallEnded"
];

function getCallbackSnippet(callbacks) {
  return `window.iAdvizeCallbacks = {${callbacks.reduce(
    (all, callbackName) => (
      (all += `\n  ${callbackName}: function (obj) {\n    console.log('${callbackName}', obj);\n  },`),
      all
    ),
    ""
  )}\n};`;
}

const snippets = {
  CALLBACKS: {
    label: '💬 Livechat events callbacks',
    code: getCallbackSnippet(callbacks),
  },
  SIMPLE_AUTH: {
    label: '🔒 Simple authentication callback',
    code: `window.iAdvizeCallbacks = {
  retrieveAuthenticationParameters: function (authenticateWith) {

    // [...] The call to your backend

    // If the backend says the visitor is authenticated, and return its user ID:
    authenticateWith({type: 'SIMPLE_AUTHENTICATION', userId: 'YOUR_USER_ID'});

    // If the visitor is not authenticated, just use "ANONYMOUS":
    // authenticateWith({type: 'ANONYMOUS'});

  }
};`
  },
  SECURED_AUTH: {
    label: '🔐 Secured authentication callback',
    code: `window.iAdvizeCallbacks = {
  retrieveAuthenticationParameters: function (authenticateWith) {

    // [...] The call to your backend

    // If the backend says the visitor is authenticated, and return its JWE:
    authenticateWith({type: 'SECURED_AUTHENTICATION', token: 'YOUR_JWE_TOKEN'});

    // If the visitor is not authenticated, just use "ANONYMOUS":
    // authenticateWith({ type: 'ANONYMOUS' });

  }
};`
  },
  SECURED_AUTH_SAMPLE: {
    label: '🔐 Secured authentication callback (sample project)',
    code: `window.iAdvizeCallbacks = {

  //Check documentation at https://github.com/iadvize/authentication-sample 
  //to start a sample authentication server listening on localhost:9000
  retrieveAuthenticationParameters: function (authenticateWith) {

    const userId = 'aaa-bbb-ccc'; //fake userId
    
    let auth_url = "http://localhost:9000/api/token/" + userId;
    console.log("Making the post request to retrieve token on url: " + auth_url);

    fetch(auth_url, {
      method: 'POST'
    }).then(function (response) {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    }).then(function (data) {
      console.log("setting the auth token !")
      authenticateWith({ type: 'SECURED_AUTHENTICATION', token: data.token });
    }).catch(function (error) {
      console.warn('Something went wrong.', error);
    });
  }
};`
  },
  NONE: {
    label: 'None',
    code: null,
  }
};

const helpLinks = {
  'Available livechat JavaScript Callbacks': 'https://developers.iadvize.com/documentation/javascript-callbacks#callbacks-index',
  'Simple authentication guide': 'https://help.iadvize.com/hc/en-gb/articles/360019650780',
  'Secured authentication guide': 'https://help.iadvize.com/hc/en-gb/articles/360016175139'
};

export default {
  components: {
    AppCode
  },
  data() {
    return {
      helpLinks,
      snippets,
      errors: [],
      jsCallbackChangedEventSent: false
    };
  },
  methods: {
    loadSnippet(snippetName) {
      this.code = snippets[snippetName] ? snippets[snippetName].code : '';
      this.action('Code', 'LoadSnippet', snippetName);
    },
    action(eventCategory, eventAction, eventLabel, eventValue) {
      this.$gtag.event(eventCategory, eventAction, eventLabel, eventValue);
    },
    onCmReady() {},
    onCmFocus() {},
    onCodeChange(newCode) {
      if (this.code !== newCode && this.code && !this.jsCallbackChangedEventSent) {
        this.action('Code', 'Edited');
        this.jsCallbackChangedEventSent = true;
      }
      this.code = newCode;
      this.decorateIDZCallbacks();
    },
    log(callbackName, obj) {
      this.$store.dispatch(
        "addLog",
        Log.info(
          Log.topic.CALLBACK,
          `Callback "${callbackName}" triggered`,
          obj
        )
      );
    },
    getLoggerCallbacks() {
      return callbacks.reduce((callbacks, callbackName) => (
        (callbacks[callbackName] = obj => {
          this.log(callbackName, obj);
        }), callbacks)
        , {}
      );
    },
    decorateIDZCallbacks() {

      this.evalCode();

      const loggerCallbacks = this.getLoggerCallbacks();
      const userCallbacks = {...window.iAdvizeCallbacks};

      const decoratedUserCallbacks = Object.keys(userCallbacks)
        .reduce((callbacks, userCallbackName) => {
          return (
            (callbacks[userCallbackName] = obj => {
              if (loggerCallbacks[userCallbackName]) {
                loggerCallbacks[userCallbackName](obj);
              }
              userCallbacks[userCallbackName](obj);
            }),
            callbacks
          );
        }, {});

        window.iAdvizeCallbacks = {
          ...loggerCallbacks,
          ...decoratedUserCallbacks
        };

    },
    evalCode() {
      try {
        // tslint:disable-next-line
        eval(this.code);
        this.errors = [];
      } catch (error) {
        this.errors.push(error);
      }
    }
  },
  computed: {
    ...mapFields(["settings.code", "isTagLoaded"]),
    codemirror() {
      return this.$refs.cmEditor.codemirror;
    },
    errorsList() {
      return this.errors.slice(0).reverse();
    }
  },
  mounted() {
    // this.code = this.code || null;
    this.decorateIDZCallbacks(callbacks);
  }
};
</script>

<style>
.playground-code .CodeMirror {
  height: 450px;
}

.list-group-errors {
  max-height: 120px !important;
  overflow: scroll;
}
</style>
