const iAdvizeMainTagClassName = "iadvize-main-tag";

function createTagElement({ hostname, sid, lang, tpl }) {
  resetIDZTag();

  var idz = document.createElement("script");
  idz.classList.add(iAdvizeMainTagClassName);
  idz.type = "text/javascript";
  idz.async = true;
  idz.src =
    document.location.protocol +
    "//" +
    hostname +
    "/iadvize.js?sid=" +
    sid +
    lang +
    tpl;

  return idz;
}

function resetIDZTag() {
  const idzMainTag = document.querySelector(`.${iAdvizeMainTagClassName}`);
  if (idzMainTag) {
    idzMainTag.remove();
  }
  delete window.iAdvize;
}

let storageReferences = {
	
};

function getStorageReference(hostname, onStorageReady) {

  let storageRef = storageReferences[hostname];
  let iframe;
  
  function postMessage(message) {
    iframe.contentWindow.postMessage(message, "*");
  }

  const operations = {
    resetVisitor() {
      postMessage(`reset-visitor-vuid#del#vuid`);
    },
    setVisitor(id) {
      postMessage(`set-visitor-vuid#set#vuid#${id}`)
    }
  };
  
  // CASE: iFrame does not yet exists
  if (!storageRef) {
  
    iframe = document.createElement('iframe');
    iframe.style.display = "none";
    iframe.src = `https://${hostname}/storage.php?type=local&o=${document.location.origin}`;
    
    storageReferences[hostname] = {
      iframe: null,
      listeners: [],
    };

    iframe.onload = () => {
      storageReferences[hostname].iframe = iframe;
      storageReferences[hostname].listeners.forEach(listener => listener(operations));
    };
    
    document.body.appendChild(iframe);
  
  }
  
  // CASE: iFrame exists and loaded
  if (storageRef && storageRef.iframe) {
    iframe = storageRef.iframe;
    onStorageReady(operations);
    return;
  }
  
  // CASE: iFrame already exists but not yet loaded
  storageReferences[hostname].listeners.push(onStorageReady);

}

export default {
  setVisitor(vuid, callback = function(){}) {

    getStorageReference('halc.iadvize.com', (operations) => {
      operations.setVisitor(vuid);
      callback(vuid);
    });

  },
  resetVisitor() {

    if ('iAdvize' in window) {
      const noop = () => {};
      window.iAdvize.require('storage').del('vuid', noop, 'cookie');
      window.iAdvize.require('storage').del('jwtIdentity', noop, 'cookie');
      setTimeout(() => document.location.reload(), 1000);
    }

  },

  appendTag(options, callback) {
    const IDZTag = createTagElement(options);
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(IDZTag, firstScriptTag);

    const checkiAdvizeTag = setInterval(() => {
      let get = null;
      try {
        // First implementation
        get = window.iAdvize?.get;
      } catch {
        try {
          // Current implementation
          get = window.iAdvize?.get
          // eslint-disable-next-line no-debugger
          debugger
        } catch {
          get = null;
        }
      }
      if (get !== null) {
        clearInterval(checkiAdvizeTag);
        clearTimeout(timeout);
        if (callback) {
          callback(null);
        }
      }
    }, 250);

    const timeout = setTimeout(() => {
      let error = {
        type: 'UNKNOWN',
        message: 'Unknown error',
      };

      if (!window.iAdvize) {
        error = {
          type: 'UNKNOWN',
          message: 'Unable to load iAdvize',
        };
      } else if (!window.iAdvize.platform) {
        error = {
          type: 'BAD_ENVIRONMENT',
          message: 'Check the environment you set, it may be wrong.',
        };
      } else if (!document.location.href.includes(window.iAdvize.website_url)) {
        error = {
          type: 'BAD_HOSTNAME',
          message: 'URL does not match the website / project URL set in iAdvize Admin',
        };
      }

      resetIDZTag();
      clearInterval(checkiAdvizeTag);
      callback(error);
    }, 8000);
  },

  navigateTo(path) {
    path =
      path ||
      prompt("Please provide a value for the iAdvize.navigate() function");

    if (path && window.iAdvize) {
      window.iAdvize.navigate(path);
    }

    return path;
  },

  setCustomData(customData) {
    window.idzCustomData = JSON.parse(JSON.stringify(customData || {}));
    return Object.keys(window.idzCustomData).length ? {...window.idzCustomData} : null;
  },

  resetCustomData() {
    window.idzCustomData = null;
  },

  setTransactionData(transactionData) {
    if(!transactionData || Object.keys(transactionData).length === 0) {
      delete window.idzTrans;
      return null;
    }
    window.idzTrans = JSON.parse(JSON.stringify(transactionData || {}));
    return {...window.idzTrans};
  },

  recordTransaction(transactionData) {
    const transactionDataPopulated = this.setTransactionData(transactionData);
    if (window.iAdvize) {
      window.iAdvize.recordTransaction();
      return transactionDataPopulated;
    }
    return false;
  },

  resetTransactionData() {
    window.idzTrans = null;
  }
};
