export default {
  state: {
    logs: []
  },
  mutations: {
    ADD_LOG: (state, log) => (state.logs = [...state.logs, log])
  },
  actions: {
    addLog({ commit }, log) {
      setTimeout(() => commit("ADD_LOG", log));
    }
  },
  getters: {
    lastLog: state => state.logs.slice(-1)[0],
    allLogs: state => state.logs,
    allLogsReversed: state => [...state.logs].reverse()
  }
};
