import iAdvize from "../helpers/iAdvize";
import queryParams from "../helpers/queryParams";
import Log from "../models/Log";

export default {
  gaEvent(context, event) {
    if (this._vm && this._vm.$ga) {
      this._vm.$ga.event(event);
    }
  },
  addCustomData({ dispatch, getters }, standardCustomData) {
    const cd = [...getters.customData];
    cd.push({
      key: standardCustomData || "",
      value: null
    });
    dispatch("updateSettings", { cd });
  },
  removeCustomData({ dispatch, getters }, index) {
    const cd = [...getters.customData];
    cd.splice(index, 1);
    dispatch("updateSettings", { cd });
  },
  resetCustomData({ dispatch, commit }) {
    commit("SET_SETTINGS", { settings: { cd: [] } });
    dispatch("addLog", Log.info(Log.topic.CUSTOM_DATA, `Custom data clear`));
  },
  updateSettings({ commit }, settings) {
    commit("SET_SETTINGS", { settings });
  },
  updateURL({ commit, getters }) {
    const url = queryParams.buildFullURLQueryParamsFromSettings(
      getters.settingsForQueryParams
    );
    commit("SET_URL", { url });
    window.history.replaceState({ path: url }, "", url);
  },
  loadTag({ commit, dispatch, getters, state }) {
    commit("SET_LOADING", true);

    const customData = iAdvize.setCustomData(getters.validCustomDataKeyValue);
    const transactionData = iAdvize.setTransactionData(getters.validTransactionDataKeyValue);

    if (customData) {
      dispatch('gaEvent', {
        eventCategory: 'CustomData',
        eventAction: 'Load',
      });
      dispatch(
        "addLog",
        Log.info(Log.topic.CUSTOM_DATA, `Custom Data set`, customData)
      );
    }

    if (transactionData) {
      dispatch('gaEvent', {
        eventCategory: 'Transaction',
        eventAction: 'Load',
      });
      dispatch(
        "addLog",
        Log.info(
          Log.topic.TRANSACTION,
          `Transaction recorded at tag loading`,
          transactionData
        )
      );
    }

    if (state.settings.vuid) {
      iAdvize.setVisitor(state.settings.vuid, function(vuid) {
        dispatch('gaEvent', {
          eventCategory: 'Tag',
          eventAction: 'Impersonate',
        });
        dispatch(
          "addLog",
          Log.info(Log.topic.VISITOR, `VUID ${vuid} successfully set!`, {vuid})
        );
      });
    }

    iAdvize.appendTag(getters.idzTagOptions, error => {
      if (error) {
        commit("SET_LOADING", false);
        dispatch('gaEvent', {
          eventCategory: 'Tag',
          eventAction: 'Error',
          eventLabel: error.type,
        });
        dispatch(
          "addLog",
          Log.error(Log.topic.TAG, error.message, error)
        );
        return;
      }

      let tagVersion;
      try {
        // First implementation
        tagVersion = window.iAdvize?.get?.('tagVersion');
      } catch {
        try {
          // Current implementation
          tagVersion = window.iAdvize?.get?.('tag:version');
        } catch {
          tagVersion = 'unknown';
        }
      }

      commit("SET_LOADING", false);
      commit("SET_TAG_LOADED", true);
      dispatch('gaEvent', {
        eventCategory: 'Tag',
        eventAction: 'Load',
        eventLabel: (state.settings.env+'-'+state.settings.sid).toUpperCase(),
      });
      dispatch(
        "addLog",
        Log.info(
          Log.topic.TAG,
          `iAdvize main tag successfully loaded for SID ${state.settings.sid}.
           Tag version : ${tagVersion}
          `,
          {
            env: state.settings.env,
            sid: state.settings.sid,
            lang: state.settings.lang,
            tpl: state.settings.tpl,
            tagVersion,
          })
      );
    });
  },
  navigateTo({ dispatch }, path) {
    const navigatedPath = iAdvize.navigateTo(path);
    if (navigatedPath) {
      // commit("SET_SETTINGS", {settings: {path: navigatedPath}});
      dispatch(
        "addLog",
        Log.info(
          Log.topic.NAVIGATE,
          `iAdvize.navigate("${navigatedPath}") successfully called`
        )
      );
    }
  },
  resetVisitor({ commit, getters }) {
    commit("SET_LOADING", true);
    iAdvize.resetVisitor(getters.envDomains);
  },
  recordTransaction({ dispatch, getters }) {
    const transactionData = iAdvize.recordTransaction(
      getters.validTransactionDataKeyValue
    );
    if (transactionData) {
      dispatch(
        "addLog",
        Log.info(
          Log.topic.TRANSACTION,
          `Transaction Recorded manually`,
          transactionData
        )
      );
    }
  },
  resetTransactionData({ state, dispatch }) {
    if (state.isTagLoaded) {
      dispatch(
        "addLog",
        Log.warn(
          Log.topic.TRANSACTION,
          `Transaction data NOT cleared because iAdvize tag already loaded`
        )
      );
    } else {
      iAdvize.resetTransactionData();
      dispatch("updateSettings", { tID: null, cartAmount: null });
      dispatch(
        "addLog",
        Log.info(Log.topic.TRANSACTION, `Transaction data has been cleared`)
      );
    }
  }
};
