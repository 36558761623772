import Vuex from "vuex";
import Vue from "vue";

import logger from "./modules/logger.js";
import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";
import plugins from "./plugins.js";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    logger
  },
  state: {
    loading: false,
    isBrowserBack: false,
    isTagLoaded: false,
    url: "",
    settings: {
      env: "ha",
      sid: null,
      lang: null,
      tpl: null,
      vuid: null,
      autoload: false,
      domain: null,
      path: null,
      cd: [],
      tID: null,
      code: null,
      cartAmount: null,
      fba: null,
      fbb: null,
      fbu: null,
    },
    paths: ["chat", "call", "video", "bot"],
    standardsCustomData: [
      "cust_name",
      "cust_firstname",
      "cust_address",
      "cust_city",
      "cust_zipcode",
      "cust_country",
      "cust_email",
      "cust_phonenumber",
      "extID"
    ],
    envDomainMappings: {
      ha: "halc",
      sd: "lc"
    },
    screen: {
      xs: false,
      sm: false,
      md: false,
      lg: false,
      xl: false,
      gt: {
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false
      },
      lt: {
        xs: false,
        sm: false,
        md: false,
        lg: false,
        xl: false
      }
    }
  },
  mutations,
  getters,
  actions,
  plugins
});
