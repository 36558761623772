function getQueryParamsKeyDefinition(key) {
  const [
    ,
    propertyName,
    arrayDefinition,
    associativeKey
  ] = /([\w-_]*)(\[([\w-_]*)\])?/g.exec(key);

  return {
    type: arrayDefinition
      ? associativeKey
        ? "associativeArray"
        : "array"
      : "keyvalue",
    propertyName,
    associativeKey: associativeKey || null
  };
}

export default {
  buildFullURLQueryParamsFromSettings(settings) {
    const url = new URLSearchParams();

    for (const key in settings) {
      if (Array.isArray(settings[key])) {
        settings[key].forEach(cd => {
          if (typeof cd === "object") {
            url.append(`${key}[${cd.key}]`, cd.value || "");
          } else {
            url.append(`${key}[]`, cd || "");
          }
        });
      } else {
        if (settings[key]) {
          url.append(key, settings[key] || "");
        }
      }
    }

    const stringQueryParams = decodeURIComponent(
      url.toString() ? "?" + url.toString() : ""
    );
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      stringQueryParams
    );
  },

  extractAllURLQueryParams() {
    const queryParams = new URLSearchParams(window.location.search);
    const params = {};

    queryParams.forEach((value, key) => {
      const {
        type,
        propertyName,
        associativeKey
      } = getQueryParamsKeyDefinition(key);
      switch (type) {
        case "array":
          if (!params[propertyName]) {
            params[propertyName] = [];
          }

          params[propertyName].push(value);

          break;

        case "associativeArray":
          if (!params[propertyName]) {
            params[propertyName] = [];
          }

          params[propertyName].push({
            key: associativeKey,
            value
          });

          break;

        case "keyvalue":
        default:
          params[propertyName] = value;

          break;
      }
    });

    return params;
  }
};
